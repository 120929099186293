import React, { useState, useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { Typography, InputAdornment, Button } from '@material-ui/core';
import DatePicker from './DatePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import {
	TDR,
	sortByTime,
	formatMonetaryValue,
	getArrayFromRange,
	to12HourTimeFormat,
	removeReservationFromTimeSlots,
	unpackTimeSlots
} from 'tdr-common';
import PeopleIcon from '@material-ui/icons/People';
import { ModifyReservationContext } from '../context/ModifyReservationContext';
import { TableContext } from '../context/TableContext';
import { TimeSlotContext } from '../context/TimeSlotContext';
import Select from './Select';
import PillContainer from './PillContainer';

namespace Availability {
	export type Props = {
		reservation: TDR.Reservation,
		table: TDR.Table,
		restaurant: TDR.Restaurant,
		onProceed: (price: number, selectedTime: string, selectedDate: Date, guests: number)=>void
	};
}

const Availability = ({ onProceed, reservation, table, restaurant }: Availability.Props) => {
	const {
		timeSlotsDoc,
		tableTimeSlots,
		selectedDate,
		setSelectedDate: setTimeSlotContextDate,
		pricingPolicies
	} = useContext(TimeSlotContext);
	const {
		guests: contextGuests,
		setGuests: setContextGuests,
		selectedTime, setSelectedTime,
		setSelectedDate: setReservationContextSelectedDate,
		setModifiedReservationPrice
	}: ModifyReservationContext.Value = useContext(ModifyReservationContext);
	const { selectedTable, setSelectedTable, getTablesByRestaurant } = useContext(TableContext);
	const [tables, setTables] = useState<TDR.Table[] | null>(null);

	const { time: reservationTime } = reservation;
	const reservationDate = reservationTime.toDate();
	const [hours, minutes] = reservationDate.toTimeString()
		.split(' ')[0]
		.split(':');

	const isCurrentlyReservedTable = (
		reservation.tableId === (selectedTable?.id || table?.id) &&
		reservation.time.toDate().toDateString() === selectedDate.toDateString()
	);

	const hasModifications = (
		!isCurrentlyReservedTable ||
		reservation.guests !== contextGuests ||
		selectedTime !== `${hours}:${minutes}` ||
		!(
			reservationDate.getFullYear() === selectedDate.getFullYear() &&
			reservationDate.getMonth() === selectedDate.getMonth() &&
			reservationDate.getDate() === selectedDate.getDate()
		)
	);

	const [clearedTableTimeSlots, setClearedTableTimeSlots] = useState(tableTimeSlots);

	useEffect(() => {
		setSelectedTime(`${hours}:${minutes}`);
		setContextGuests(reservation.guests);
		setSelectedTable(table);
	}, []);

	useEffect(() => {
		if (restaurant) {
			getTablesByRestaurant(restaurant.id).then(tables => {
				setTables(tables);
			});
		}
		else {
			setTables([]);
		}
	}, [restaurant]);


	useEffect(() => {
		if (isCurrentlyReservedTable) {
			setSelectedTime(`${hours}:${minutes}`);
		}
		else {
			setSelectedTime(null);
		}

		// Clear the timeslots that are taken by the current reservation
		if (timeSlotsDoc) {
			const tsDocWithoutRes = removeReservationFromTimeSlots(reservation.id, timeSlotsDoc);
			const slotsWithoutRes = unpackTimeSlots(restaurant, table, tsDocWithoutRes, { ignoreCutoffs: true });
			setClearedTableTimeSlots(slotsWithoutRes.timeSlots);
		}
		else {
			setClearedTableTimeSlots(tableTimeSlots);
		}
	}, [timeSlotsDoc, tableTimeSlots]);

	const shouldDisableDate = (day: DateTime) => (
		restaurant.closed.weekdays.includes(day.weekdayLong) ||
		restaurant.closed.dates.map((obj)=>obj.date).includes(day.toFormat('MMMM dd, yyyy'))
	);

	const hasShift = (_tableTimeSlots, shift) => Object.keys(_tableTimeSlots).some((time) => _tableTimeSlots[time].timeLabel === shift);

	const getTimeSlotsByShift = (_tableTimeSlots, shift) => (
		hasShift(_tableTimeSlots, shift)
			? Object.keys(_tableTimeSlots)
				.filter((time: string) => _tableTimeSlots[time].timeLabel === shift && _tableTimeSlots[time].available)
				.map((time: string) => ({ time, ..._tableTimeSlots[time] }))
				.sort(sortByTime)
			: null
	);

	const brunchTimes = getTimeSlotsByShift(clearedTableTimeSlots, 'Brunch');
	const lunchTimes = getTimeSlotsByShift(clearedTableTimeSlots, 'Lunch');
	const earlyBirdTimes = getTimeSlotsByShift(clearedTableTimeSlots, 'Early Bird');
	const flyingFlockTimes = getTimeSlotsByShift(clearedTableTimeSlots, 'Flying Flock');
	const nightOwlTimes = getTimeSlotsByShift(clearedTableTimeSlots, 'Night Owl');

	const renderPillContainerByShift = (timesArray, shift, price) => timesArray && (
		<div className='timeslots-section'>
			<div className='timeslots-text'>
				<Typography variant='subtitle1'>{shift}</Typography>
				<Typography variant='subtitle1'>{!isEmpty(timesArray) ? formatMonetaryValue(price,restaurant?.currency, true) : null}</Typography>
			</div>
			{!isEmpty(timesArray)
				? <PillContainer
					items={timesArray}
					keyPrefix={shift.toLowerCase().replace(/\s/g, '-')}
					labelKey='time'
					onClick={(timeSlot) => setSelectedTime(timeSlot.time === selectedTime ? null : timeSlot.time)}
					selectedItem={selectedTime}
					formatLabelFn={to12HourTimeFormat}
				/>
				: <Typography variant='body2'>This table has no more {shift} times available for this date.</Typography>}
		</div>
	);

	const getPrice = (timesArray) => timesArray[0]?.regularPrice;

	const brunchRegularPrice = brunchTimes && getPrice(brunchTimes);
	const lunchRegularPrice = lunchTimes && getPrice(lunchTimes);
	const earlyBirdRegularPrice = earlyBirdTimes && getPrice(earlyBirdTimes);
	const flyingFlockRegularPrice = flyingFlockTimes && getPrice(flyingFlockTimes);
	const nightOwlRegularPrice = nightOwlTimes && getPrice(nightOwlTimes);

	const numberOfGuestDropdownOptions = getArrayFromRange(table?.minGuests, table?.maxGuests)
		.map((option) => ({ label: `${option} Guests`, value: option, isSelected: option === contextGuests }));

	return (
		<div className='super-admin-timeslot-modification'>
			<div className='header'>
				<Typography variant='h6'>Modify Reservation</Typography>
			</div>
			<Select
				value={selectedTable?.id || table.id}
				onChange={(evt)=> {
					setSelectedTable(tables.find((t) => t.id === evt.target.value));
				}}
				options={tables.map((_table) => ({ label: _table.internalName || _table.name, value: _table.id }))}
			/>
			<MuiPickersUtilsProvider utils={LuxonUtils}>
				<DatePicker
					alwaysShowOpenedCalendarOnMobile={true}
					mobileDatePickerOpened={true}
					selectedDate={selectedDate}
					onChange={(value) => {
						setTimeSlotContextDate(new Date(value));
						setReservationContextSelectedDate(new Date(value));
					}}
					shouldDisableDate={(day: DateTime) => shouldDisableDate(day)}
				/>
			</MuiPickersUtilsProvider>

			{renderPillContainerByShift(brunchTimes, 'Brunch', brunchRegularPrice)}
			{renderPillContainerByShift(lunchTimes, 'Lunch', lunchRegularPrice)}
			{renderPillContainerByShift(earlyBirdTimes, 'Early Bird', earlyBirdRegularPrice)}
			{renderPillContainerByShift(flyingFlockTimes, 'Flying Flock', flyingFlockRegularPrice)}
			{renderPillContainerByShift(nightOwlTimes, 'Night Owl', nightOwlRegularPrice)}

			<Select
				style={{ margin: '2em 0' }}
				value={contextGuests || table.minGuests}
				onChange={(evt) => setContextGuests(Number(evt.target.value))}
				startAdornment={(
					<InputAdornment position='start'>
						<PeopleIcon style={{ width: '1.25em', height: '1.25em' }} />
					</InputAdornment>
				)}
				options={numberOfGuestDropdownOptions}
			/>
			<Button
				disabled={isNull(selectedDate) || isNull(selectedTime) || !hasModifications}
				variant='contained'
				color='primary'
				data-cy='proceed-availability-btn'
				onClick={async () => {
					setReservationContextSelectedDate(selectedDate);
					const price = await setModifiedReservationPrice(pricingPolicies, clearedTableTimeSlots, reservation, {
						selectedTime,
						selectedDate,
						contextGuests,
						selectedTable
					});
					onProceed(price, selectedTime, selectedDate, contextGuests);
				}}
			>
					Proceed
			</Button>
		</div>
	);
};

export default Availability;
